<template>
  <div>
    <el-container>
      <el-main style="padding-bottom: 60px;">
        <span style="font-weight: bold">{{currentEpisode.info.title}}</span>
        <el-tabs v-if="currentEpisode != null" v-model="activeTab" @tab-click="tabClicked">
          <el-tab-pane label="基本信息" name="info">
            <el-form label-position="right" label-width="70px">
              <el-form-item label="封面">
                <el-image v-if="currentEpisode.info.cover != null && !useChannelCover" :src="currentEpisode.info.cover" style="width: 400px; max-width: 80%;" fit="cover"></el-image>
                <el-input v-model="currentEpisode.info.cover">
                  <el-switch slot="prepend" :value="useChannelCover" active-text="使用频道封面" @change="toggleChanged"></el-switch>
                  <el-button slot="append" icon="el-icon-upload" @click="uploadImage">上传</el-button>
                </el-input>
                <el-progress v-if="uploadProgress>0" :percentage="uploadProgress" :show-text="false" :stroke-width="3" stroke-linecap="square"></el-progress>
                <input id="fileUpload" type="file" accept="image/png, image/jpeg" hidden @change="imageFileChanged">
              </el-form-item>
              <el-form-item label="音频">
                <input id="mediaUpload" type="file" accept="audio/mp3, audio/x-m4a" hidden @change="mediaFileChanged">
                <el-input :value="currentEpisode.info.media" disabled>
                  <el-button slot="append" icon="el-icon-upload" @click="uploadMedia">上传</el-button>
                </el-input>
              </el-form-item>
              <el-form-item label="名称">
                <el-input v-model="currentEpisode.info.title" placeholder="必须要有名字哦"></el-input>
              </el-form-item>
              <el-form-item label="副标题">
                <el-input v-model="currentEpisode.info.subtitle" placeholder="可以没有"></el-input>
              </el-form-item>
              <el-form-item label="发布日期">
                <el-date-picker v-model="currentEpisode.info.pubDate" type="datetime" placeholder="选择日期时间" :clearable="false" :editable="false">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="网站">
                <el-input v-model="currentEpisode.info.website" placeholder="如果不填则是PodAPI提供的播放页面"></el-input>
              </el-form-item>
              <el-form-item label="简介">
                <el-input type="textarea" autosize placeholder="请输入内容" v-model="currentEpisode.info.summary"></el-input>
              </el-form-item>
              <el-form-item label="详细介绍">
                <Tiptap v-model="currentEpisode.info.description" ref="editor"></Tiptap>
              </el-form-item>
              <el-form-item>
                <el-popconfirm title="确定要删除这一期节目吗？" icon="el-icon-warning" @confirm="deleteEpisode">
                  <el-button type="danger" size="small" icon="el-icon-delete" slot="reference">删除本集</el-button>
                </el-popconfirm>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane name="chapter">
            <span slot="label"><i class="el-icon-notebook-2"></i> 章节</span>
            <Timeline type="chapter" v-if="currentEpisode.timedMetadata && currentEpisode.timedMetadata.chapters" v-model="currentEpisode.timedMetadata.chapters" description="这些时间点可以方便听众在长音频中找到感兴趣的地方" :editor="this.$refs.editor"/>
          </el-tab-pane>
          <el-tab-pane name="poi">
            <span slot="label"><i class="el-icon-location-outline"></i> 关键时间</span>
            <Timeline type="poi" v-if="currentEpisode.timedMetadata && currentEpisode.timedMetadata.poi" v-model="currentEpisode.timedMetadata.poi" description="时间轴信息让主播用其他形式表达无法用声音说明白的问题" :editor="this.$refs.editor"/>
          </el-tab-pane>
          <el-tab-pane name="highlight">
            <span slot="label"><i class="el-icon-crop"></i> 精彩片段</span>
            <Timeline type="highlight" v-if="currentEpisode.timedMetadata && currentEpisode.timedMetadata.highlights" v-model="currentEpisode.timedMetadata.highlights" description="用短音频发现长音频" :editor="this.$refs.editor"/>
          </el-tab-pane>
          <el-tab-pane name="music">
            <span slot="label"><i class="el-icon-headset"></i> 音乐</span>
            <Timeline type="music" v-if="currentEpisode.timedMetadata && currentEpisode.timedMetadata.music" v-model="currentEpisode.timedMetadata.music" description="标注出节目中播放的音乐片段，有助于获得播放版权" :editor="this.$refs.editor"/>
          </el-tab-pane>
          <el-tab-pane v-if="currentEpisode.stats" name="stats">
            <span slot="label"><i class="el-icon-data-analysis"></i> 播放数据</span>
            <el-form label-suffix=":">
              <el-form-item label="播放次数">
                {{currentEpisode.stats.total}}
              </el-form-item>
              <el-form-item label="播放人数">
                {{currentEpisode.stats.totalUnique}}
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </el-main>
      <el-footer v-if="currentEpisode" style="z-index: 100;">
        <Player :url="currentEpisode.info.media" v-on:durationChanged="durationChanged"/>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import { mapFields } from 'vuex-map-fields';
import { Message } from 'element-ui';
import Player from '../components/Player.vue';
import Tiptap from '../components/Editor.vue';
import Timeline from '../components/Timeline.vue';

export default {
  data() {
    return {
      dialogVisible: false,
      activeTab: "info"
    }
  },
  components: {
    Player,
    Tiptap,
    Timeline
  },
  computed: {
    ...mapFields([
      'channels',
      'selectedChannelId',
      'episodes',
      'uploadProgress'
    ]),
    currentEpisode() {
      return this.episodes.find(ep => ep.id === this.$route.params.id);
    },
    currentChannel() {
      return this.channels.find(ch => ch.id === this.selectedChannelId);
    },
		useChannelCover() {
      return this.currentEpisode.info.cover === this.currentChannel.info.cover;
    }
  },
  methods: {
    uploadImage() {
      document.getElementById("fileUpload").click();
    },
    imageFileChanged() {
      const fileInput = document.getElementById("fileUpload");
      const file2upload = fileInput.files[0];
      if (file2upload) {
        if (file2upload.size > 2 * 1024 * 1024) {
          Message.error("图片文件大小需要小于2MB");
        } else {
          this.$store.dispatch("upload", {file:file2upload, folder:"images"}).then(imageSrc => {
            this.currentEpisode.info.cover = imageSrc;
          });
        }
      }
    },
		toggleChanged(checked) {
			if (checked) {
				this.currentEpisode.info.cover = this.currentChannel.info.cover;
			} else {
				this.currentEpisode.info.cover = null;
			}
		},
    uploadMedia() {
      document.getElementById("mediaUpload").click();
    },
    mediaFileChanged() {
      const fileInput = document.getElementById("mediaUpload");
      const file2upload = fileInput.files[0];
      if (file2upload) {
        if (file2upload.size > 150 * 1024 * 1024) {
          Message.error("为了听众的体验，媒体文件大小需要小于150MB");
        } else {
          this.$store.dispatch("upload", {file:file2upload, folder:"media", episodeID: this.$route.params.id}).then(mediaSrc => {
            //console.log(mediaSrc);
            this.currentEpisode.info.media = mediaSrc;
            this.currentEpisode.info.mediaSize = file2upload.size;
            this.currentEpisode.info.mediaType = file2upload.type;
            if (!this.currentEpisode.timedMetadata) {
              this.$notify({
                title: '提示',
                message: '别忘了你可以添加类似章节、关键时间等Podcast 2.0信息哦',
                duration: 0
              });
            }
          }).catch(error => {
            Message.error("上传错误 " + error);
          });
        }
      }
    },
    durationChanged(duration) {
      this.currentEpisode.info.duration = duration;
    },
    deleteEpisode() {
      let index = this.episodes.indexOf(this.currentEpisode);
      if (index >= 0) {
        this.$store.dispatch("sendRequest", {method: "deleteEpisode", params: {episodeId: this.currentEpisode.id}}).then(response => {
          
        }).finally(() => {
          this.$router.push("/");
          this.episodes.splice(index, 1);
        });
      }
    },
    createMetadataIfNeeded () {
      if (!this.currentEpisode.timedMetadata) {
        this.currentEpisode.timedMetadata = {};
      }
    },
    tabClicked(tab) {
      //console.log(this.activeTab);
      
      if (this.activeTab === "chapter") {
        this.createMetadataIfNeeded();
        if (!this.currentEpisode.timedMetadata.chapters) {
          this.currentEpisode.timedMetadata.chapters = [];
        }
      } else if (this.activeTab === "poi") {
        this.createMetadataIfNeeded();
        if (!this.currentEpisode.timedMetadata.poi) {
          this.currentEpisode.timedMetadata.poi = [];
        }
      } else if (this.activeTab === "highlight") {
        this.createMetadataIfNeeded();
        if (!this.currentEpisode.timedMetadata.highlights) {
          this.currentEpisode.timedMetadata.highlights = [];
        }
      } else if (this.activeTab === "music") {
        this.createMetadataIfNeeded();
        if (!this.currentEpisode.timedMetadata.music) {
          this.currentEpisode.timedMetadata.music = [];
        }
      }
    },
  },
}
</script>

<style scoped>
  .el-footer {
    position: fixed;
    left: var(--left-width);
    bottom: 0px;
    width: calc(100vw - var(--left-width));
    background-color: #454545;
  }
</style>