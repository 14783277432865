<template>
  <div>
    <el-form label-position="right" label-width="80px" v-if="currentChannel != null">
      <el-form-item label="封面">
        <el-image :src="currentChannel.info.cover" style="width: 400px; max-width: 80%;" fit="cover"></el-image>
        <el-input v-model="currentChannel.info.cover" disabled>
          <el-button slot="append" icon="el-icon-upload" @click="uploadImage">上传</el-button>
        </el-input>
        <el-progress v-if="uploadProgress>0" :percentage="uploadProgress" :show-text="false" stroke-width="3" stroke-linecap="square"></el-progress>
        <input id="fileUpload" type="file" accept="image/png, image/jpeg" hidden @change="imageFileChanged">
      </el-form-item>
      <el-form-item label="名称">
        <el-input v-model="currentChannel.info.title"></el-input>
      </el-form-item>
      <el-form-item label="副标题">
        <el-input v-model="currentChannel.info.subtitle"></el-input>
      </el-form-item>
      <el-form-item label="主播">
        <el-input v-model="currentChannel.info.author"></el-input>
      </el-form-item>
      <el-form-item label="Email">
        <el-input v-model="currentChannel.info.email"></el-input>
      </el-form-item>
      <el-form-item label="网站">
        <el-input v-model="currentChannel.info.website"></el-input>
      </el-form-item>
      <el-form-item label="分类">
        <el-select v-model="currentChannel.info.category" placeholder="请选择">
          <el-option value="Arts" label="艺术"></el-option>
          <el-option value="Business" label="商业"></el-option>
          <el-option value="Comedy" label="喜剧"></el-option>
          <el-option value="Education" label="教育"></el-option>
          <el-option value="Fiction" label="小说"></el-option>
          <el-option value="Government" label="政府"></el-option>
          <el-option value="History" label="历史"></el-option>
          <el-option value="Health &amp; Fitness" label="健康和健身"></el-option>
          <el-option value="Kids &amp; Family" label="孩子和家庭"></el-option>
          <el-option value="Leisure" label="休闲"></el-option>
          <el-option value="Music" label="音乐"></el-option>
          <el-option value="News" label="新闻"></el-option>
          <el-option value="Religion &amp; Spirituality" label="宗教"></el-option>
          <el-option value="Science" label="科学"></el-option>
          <el-option value="Society &amp; Culture" label="社会和文化"></el-option>
          <el-option value="Sports" label="体育"></el-option>
          <el-option value="Technology" label="科技"></el-option>
          <el-option value="True Crime" label="犯罪"></el-option>
          <el-option value="TV &amp; Film" label="电视和电影"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="简介">
        <el-input type="textarea" autosize placeholder="请输入内容" v-model="currentChannel.info.summary"></el-input>
      </el-form-item>
      <el-form-item label="详细介绍">
        <Tiptap v-model="currentChannel.info.description"></Tiptap>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { mapFields } from 'vuex-map-fields';
import { Message } from 'element-ui';
import Tiptap from '../components/Editor.vue'

export default {
  components: {
    Tiptap
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapFields([
      'channels',
      'episodes',
      'selectedChannelId',
      'uploadProgress'
    ]),
    currentChannel() {
      return this.channels.find(ch => ch.id === this.selectedChannelId);
    }
  },
  methods: {
    uploadImage() {
      document.getElementById("fileUpload").click();
    },
    imageFileChanged() {
      const fileInput = document.getElementById("fileUpload");
      const file2upload = fileInput.files[0];
      if (file2upload) {
        if (file2upload.size > 1024 * 1024) {
          Message.error("图片文件大小需要小于1MB");
        } else {
          this.$store.dispatch("upload", {file:file2upload, folder:"images"}).then(imageSrc => {
            this.currentChannel.info.cover = imageSrc;
          });
        }
      }
    }
  },
}
</script>

<style scoped>
</style>