<template>
	<div v-if="editor">
		<el-button-group>
			<el-button size="small" @click="editor.chain().toggleBold().focus().run()" :class="{ 'is-active': editor.isActive('bold') }">
				<Icon><FormatBoldFilled /></Icon>
			</el-button>
			<el-button size="small" @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
        <Icon><FormatItalicSharp /></Icon>
      </el-button>
			<el-button size="small" @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }">
        <Icon><FormatUnderlinedFilled /></Icon>
      </el-button>
      <el-button size="small" @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
        <Icon><StrikethroughSFilled /></Icon>
      </el-button>
			<el-button size="small" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
        H1
      </el-button>
      <el-button size="small" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
        H2
      </el-button>
      <el-button size="small" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
        <Icon><FormatListBulletedFilled /></Icon>
      </el-button>
		</el-button-group>
		<el-button-group>
			<el-button size="small" @click="editor.chain().focus().setTextAlign('left').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }">
				<Icon><FormatAlignLeftFilled /></Icon>
			</el-button>
			<el-button size="small" @click="editor.chain().focus().setTextAlign('center').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }">
				<Icon><FormatAlignCenterFilled /></Icon>
			</el-button>
			<el-button size="small" @click="editor.chain().focus().setTextAlign('right').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }">
				<Icon><FormatAlignRightFilled /></Icon>
			</el-button>
		</el-button-group>
		<el-button-group>
			<el-button size="small" @click="setLink" :class="{ 'is-active': editor.isActive('link') }" icon="el-icon-link">
			</el-button>
			<el-button size="small" @click="editor.chain().focus().unsetLink().run()" v-if="editor.isActive('link')" icon="el-icon-delete">
			</el-button>
			<el-button size="small" @click="addImage" icon="el-icon-picture-outline">
			</el-button>
		</el-button-group>
		<editor-content :editor="editor" style="background-color: white; border: 1px solid #dcdfe6; padding: 5px"/>
    <el-button-group>
			<el-button size="small" @click="setLink" :class="{ 'is-active': editor.isActive('link') }" icon="el-icon-link">
			</el-button>
			<el-button size="small" @click="editor.chain().focus().unsetLink().run()" v-if="editor.isActive('link')" icon="el-icon-delete">
			</el-button>
			<el-button size="small" @click="addImage" icon="el-icon-picture-outline">
			</el-button>
		</el-button-group>
    <el-dialog title="插入图片" :visible.sync="insertImageVisible" width="400px">
      <el-image v-if="isValidURL(insertImageURL)" :src="insertImageURL" style="width: 300px; max-width: 80%;" fit="cover"></el-image>
      <el-input v-model="insertImageURL" placeholder="直接输入图片URL或点击上传" clearable>
        <el-button slot="append" icon="el-icon-upload" @click="uploadImage">上传</el-button>
      </el-input>
      <el-progress v-if="uploadProgress>0" :percentage="uploadProgress" :show-text="false" :stroke-width="3" stroke-linecap="square"></el-progress>
      <input id="editorImageUpload" type="file" accept="image/png, image/jpeg" hidden @change="imageFileChanged">
      <span slot="footer" class="dialog-footer">
        <el-button @click="insertImageVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="insertImage" size="small">确定</el-button>
      </span>
    </el-dialog>
	</div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import Image from '@tiptap/extension-image'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import { MessageBox } from 'element-ui';
import { Message } from 'element-ui';
import { mapFields } from 'vuex-map-fields';
import { Icon, IconConfigProvider } from "@v2icons/utils";
import { FormatItalicSharp, FormatBoldFilled, FormatUnderlinedFilled, StrikethroughSFilled, FormatAlignLeftFilled, 
  FormatAlignCenterFilled, FormatAlignRightFilled, FormatListBulletedFilled } from "@v2icons/material";

export default {
  components: {
    EditorContent,
    Icon,
    FormatItalicSharp,
    FormatBoldFilled,
    FormatUnderlinedFilled,
    StrikethroughSFilled,
    FormatAlignLeftFilled,
    FormatAlignCenterFilled,
    FormatAlignRightFilled,
    FormatListBulletedFilled
  },
	props: {
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapFields([
      'uploadProgress'
    ])
  },
	watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value
      if (isSame) {
        return
      }
      this.editor.commands.setContent(this.value, false)
    },
  },
  data() {
    return {
      editor: null,
      insertImageVisible: false,
      insertImageURL: ""
    }
  },
	mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
				Link.configure({
					openOnClick: false
				}),
				Image,
				Underline,
				TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
      ],
      content: this.value,
      onUpdate: () => {
        // HTML
        this.$emit('input', this.editor.getHTML())

        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
    })
  },
  beforeDestroy() {
    this.editor.destroy();
  },
	methods: {
		setLink() {
      MessageBox.prompt("请输入链接", "超链接").then(response => {
        const url = response.value;
        if (url) {
          this.editor.chain().focus().setLink({ href: url }).run();
        }
      });
    },
    addImage() {
      this.insertImageURL = "";
      this.insertImageVisible = true;
    },
    insertImage() {
      if (this.insertImageURL.length) {
        this.editor.chain().focus().setImage({ src: this.insertImageURL }).run();
        this.insertImageVisible = false;
      }
    },
    uploadImage() {
      document.getElementById("editorImageUpload").click();
    },
    imageFileChanged() {
      const fileInput = document.getElementById("editorImageUpload");
      const file2upload = fileInput.files[0];
      if (file2upload) {
        if (file2upload.size > 1024 * 1024) {
          Message.error("图片文件大小需要小于1MB");
        } else {
          this.$store.dispatch("upload", {file:file2upload, folder:"images"}).then(imageSrc => {
            this.insertImageURL = imageSrc;
          });
        }
      }
    },
    getJSON() {
      return this.editor.getJSON();
    },
    getLines() {
      const content = this.getJSON();
      if (content && content.content) {
        let lines = [];
        for (const child of content.content) {
          
          if (child.type === "paragraph" && child.content) {
            let line = "";
            for (const grand of child.content) {
              if (grand.type === "text") {
                line += grand.text;
              } else if(grand.type === "hardBreak" && line.length) {
                lines.push(line);
                line = "";
              }
            }
            if (line.length) {
              lines.push(line);
            }
          } else if (child.type === "image") {
            if (child.attrs && child.attrs.src) {
              lines.push(child.attrs.src);
            }
          }
        }

        return lines;
      }
      return null;
    },
    isValidURL(value) {
        return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
    }
	},
}
</script>
<style lang="scss">
/* Basic editor styles */
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }
  p {
    line-height: 1.5;
  }
  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }
  
  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
    &.ProseMirror-selectednode {
      outline: 3px solid #68CEF8;
    }
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }
}
:focus-visible {
  outline: none;
}
</style>