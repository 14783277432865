<template>
  <div>
    <el-row :gutter="10" type="flex" justify="end">
			<el-col>
				<span style="font-size: 13px; color: gray">{{description}}</span>
			</el-col>
      <el-col style="width: 110px">
        <el-button
          icon="el-icon-plus"
          size="small"
          @click="addEvent"
          >添加事件
				</el-button>
      </el-col>
      <el-col style="width: 110px">
        <el-popconfirm title="确定删除？" @confirm="removeAllEvents">
          <el-button icon="el-icon-delete" size="small" slot="reference">全部清除</el-button>
        </el-popconfirm>
      </el-col>
    </el-row>
    <el-timeline v-if="mutableEvents && mutableEvents.length">
      <el-timeline-item v-for="(event, index) in mutableEvents" :key="event.start" 
				:timestamp="getDurationString(event.start)" placement="top" size="large" color="#272f3f">
        <el-card class="timelineCard" :body-style="{ padding: '0px' }">
          <el-image v-if="event.image" :src="event.image" fit="cover" class="image"></el-image>
					<div style="padding: 14px;">
						<el-link :href="event.link" target="_blank" :underline="false" v-if="event.link">{{event.title}}</el-link>
            <span v-else>{{event.title}}</span>
            <br/>
            <span class="content" v-if="type === 'music'">{{event.artist + ' - ' + event.album}}</span>
            <span class="content" v-else>{{event.content}}</span>
            <div slot="dot" class="bottom" style="float: right">
              <el-button type="text" @click="cardClicked(event)">播放</el-button>
              <el-popconfirm title="确定删除？" @confirm="remove(index)">
                <el-button type="text" slot="reference">删除</el-button>
              </el-popconfirm>
              <el-button type="text" @click="modify(event)">修改</el-button>
            </div>
					</div>
        </el-card>
      </el-timeline-item>
    </el-timeline>
    <el-button v-else icon="el-icon-tickets" size="small" @click="importFromShowNotes">从节目介绍(Show notes)中导入</el-button>
    <el-dialog title="编辑事件" :visible.sync="dialogVisible" width="60%">
      <el-form label-position="right" label-width="100px" v-if="currentEvent" size="small">
				<el-form-item label="开始时间(秒)">
					<el-input v-model="currentEvent.start">
						<el-button slot="append" @click="currentEvent.start = playerCurrentTime; this.$forceUpdate();">获取当前时间</el-button>
					</el-input>
				</el-form-item>
        <div v-if="type === 'chapter'">
          <el-form-item label="标题">
            <el-input v-model="currentEvent.title" placeholder="章节名称" maxlength="30" show-word-limit></el-input>
          </el-form-item>
        </div>
        <div v-else-if="type === 'poi'">
          <el-form-item label="标题">
            <el-input v-model="currentEvent.title" placeholder="" maxlength="30" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="内容">
            <el-input v-model="currentEvent.content" placeholder="详细介绍" maxlength="100" show-word-limit type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="链接">
            <el-input v-model="currentEvent.link" placeholder="https://"></el-input>
          </el-form-item>
          <el-form-item label="图片">
            <el-input v-model="currentEvent.image" placeholder="URL" maxlength="512">
              <el-button slot="append" icon="el-icon-upload" @click="uploadImage">上传</el-button>
            </el-input>
            <el-progress v-if="uploadProgress>0" :percentage="uploadProgress" :show-text="false" :stroke-width="3" stroke-linecap="square"></el-progress>
            <el-image v-if="currentEvent.image" :src="currentEvent.image" style="width: 250px;" fit="cover"></el-image>
          </el-form-item>
        </div>
        <div v-else-if="type === 'highlight'">
          <el-form-item label="结束时间(秒)">
            <el-input v-model="currentEvent.end">
              <el-button slot="append" @click="currentEvent.end = playerCurrentTime; this.$forceUpdate();">获取当前时间</el-button>
            </el-input>
          </el-form-item>
          <el-form-item label="标题">
            <el-input v-model="currentEvent.title" placeholder="高光时段主题" maxlength="30" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="内容">
            <el-input v-model="currentEvent.content" placeholder="详细介绍" maxlength="100" show-word-limit type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="图片">
            <el-input v-model="currentEvent.image" placeholder="URL" maxlength="512">
              <el-button slot="append" icon="el-icon-upload" @click="uploadImage">上传</el-button>
            </el-input>
            <el-progress v-if="uploadProgress>0" :percentage="uploadProgress" :show-text="false" :stroke-width="3" stroke-linecap="square"></el-progress>
            <el-image v-if="currentEvent.image" :src="currentEvent.image" style="width: 250px;" fit="cover"></el-image>
          </el-form-item>
        </div>
        <div v-else-if="type === 'music'">
          <el-form-item label="结束时间(秒)">
            <el-input v-model="currentEvent.end">
              <el-button slot="append" @click="currentEvent.end = playerCurrentTime">获取当前时间</el-button>
            </el-input>
          </el-form-item>
          <el-form-item label="歌曲名称">
            <el-input v-model="currentEvent.title" placeholder="请准确输入全名" maxlength="100"></el-input>
          </el-form-item>
          <el-form-item label="歌手名">
            <el-input v-model="currentEvent.artist" placeholder="请准确输入全名" maxlength="100"></el-input>
          </el-form-item>
          <el-form-item label="专辑名">
            <el-input v-model="currentEvent.album" placeholder="请准确输入全名" maxlength="100"></el-input>
          </el-form-item>
          <el-form-item label="歌曲偏移时间">
            <el-input v-model="currentEvent.offset" placeholder="秒"></el-input>
          </el-form-item>
          <el-form-item label="音量(dB)">
            <el-input-number v-model="currentEvent.gain" :max="0" :min="-120"></el-input-number>
          </el-form-item>
          <el-form-item label="背景音乐">
            <el-switch v-model="currentEvent.bgm"></el-switch>
          </el-form-item>
        </div>
        <input id="fileUpload" type="file" accept="image/png, image/jpeg" hidden @change="imageFileChanged">
			</el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="confirm" size="small">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="导入事件" :visible.sync="importDialogVisible" width="60%">
      <el-table :data="importItems" @selection-change="selectionChanged">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          label="开始时间"
          width="100">
          <template slot-scope="scope">
            {{getDurationString(scope.row.start)}}
          </template>
        </el-table-column>
        <el-table-column
          prop="title"
          label="标题"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          label="图片"
          width="120">
          <template slot-scope="scope">
            <el-avatar v-if="scope.row.image" :src="scope.row.image" shape="square" :size="100" fit="cover"></el-avatar>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="importDialogVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="importConfirm" size="small">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { Duration } from "luxon";
import { mapFields } from 'vuex-map-fields';
import { Message } from 'element-ui';

export default {
  model: {
    prop: "events",
    event: "change"
  },
  props: {
    events: Array,
    type: String,
		description: String,
    editor: Object
  },
	computed: {
    ...mapFields([
      'playerCurrentTime',
      'uploadProgress',
      'channels',
      'selectedChannelId',
    ]),
    currentChannel() {
      return this.channels.find(ch => ch.id === this.selectedChannelId);
    },
	},
  data() {
    return {
			dialogVisible: false,
			currentEvent: null,
      mutableEvents: this.events,
      importDialogVisible: false,
      importItems: [],
      importSelection: []
		};
  },
  methods: {
		addEvent() {
			this.currentEvent = {start: this.playerCurrentTime, title: ""};
      if (this.type === "highlight" || this.type === "music") {
        this.currentEvent.end = this.currentEvent.start + 10;
      }
      if (this.type === "music") {
        this.currentEvent.offset = 0;
        this.currentEvent.gain = -6;
      }
      if (this.type === "highlight" || this.type === "poi") {
        this.currentEvent.image = this.currentChannel.info.cover;
      }

			this.dialogVisible = true;
		},
    removeAllEvents() {
      this.mutableEvents = [];
    },
    notifyEventsChanged() {
      this.$emit("change", this.mutableEvents);
    },
		confirm() {
			if (this.currentEvent) {
				if (this.mutableEvents.includes(this.currentEvent) === false) {
					this.mutableEvents.push(this.currentEvent);
				}
        if (typeof this.currentEvent.start === "string" && this.currentEvent.start.includes(":")) {
          this.currentEvent.start = this.stringToDuration(this.currentEvent.start);
        }
        if (typeof this.currentEvent.end === "string" && this.currentEvent.end.includes(":")) {
          this.currentEvent.end = this.stringToDuration(this.currentEvent.end);
        }
				this.currentEvent = null;
			}
			this.dialogVisible = false;
      this.resort();
      this.notifyEventsChanged();
		},
    remove(index) {
      if (index >= 0) {
        this.mutableEvents.splice(index, 1);
        this.notifyEventsChanged();
      }
    },
    modify(event) {
      this.currentEvent = event;
      this.dialogVisible = true;
      this.notifyEventsChanged();
    },
    stringToDuration(iStr) {
      var aNum = iStr.split(':', 2);
      if (aNum.length > 2)
        return NaN;
      var aSec = null;
      if (aNum.length > 0 && aNum[0] !== '') aSec += parseInt(aNum[0]) * 60;
      if (aNum.length > 1 && aNum[1] !== '') aSec += parseInt(aNum[1]);
      return aSec;
    },
    getDurationString(time) {
      if (time) {
        //console.log(time);
        return Duration.fromMillis(parseFloat(time) * 1000).toFormat("mm:ss");
      } else {
        return "00:00";
      }
    },
    resort() {
      if (this.events) {
        this.events.sort((a, b) => (a.start > b.start) ? 1 : -1);
      }
    },
    importFromShowNotes() {
      const lines = this.editor.getLines();
      if (lines) {
        this.importItems = [];
        this.importSelection = [];
        for (const line of lines) {
          const result = /^(\d+):(\d{2})(:\d{2})?(.*)/.exec(line);
          if (result && result.length === 5) {
            let hour = 0;
            let minute = 0;
            let second = 0;
            if (result[3]) {
              hour = parseInt(result[1]);
              minute = parseInt(result[2]);
              second = parseInt(result[3]);
            } else {
              minute = parseInt(result[1]);
              second = parseInt(result[2]);
            }
            const title = result[4].trim();
            const seconds = (hour * 3600) + (minute * 60) + second;
            this.importItems.push({start: seconds, title});
          } else if (line.startsWith("http")) {
            if (this.importItems.length > 0) {
              const item = this.importItems[this.importItems.length - 1];
              if (item.image === undefined) {
                item.image = line;
              }
            }
          }
        }
        if (this.importItems.length) {
          this.importDialogVisible = true;
        } else {
          Message.error("未发现时间戳");
        }
      }
    },
    importConfirm() {
      this.importDialogVisible = false;
      if (this.importSelection.length) {
        this.mutableEvents = this.importSelection;
        this.notifyEventsChanged();
      }
    },
    selectionChanged(val) {
      this.importSelection = val;
    },
    uploadImage() {
      document.getElementById("fileUpload").click();
    },
    imageFileChanged() {
      const fileInput = document.getElementById("fileUpload");
      const file2upload = fileInput.files[0];
      if (file2upload) {
        if (file2upload.size > 1024 * 1024) {
          Message.error("图片文件大小需要小于1MB");
        } else {
          this.$store.dispatch("upload", {file:file2upload, folder:"images"}).then(imageSrc => {
            this.currentEvent.image = imageSrc;
            this.notifyEventsChanged();
          });
        }
      }
    },
    cardClicked(event) {
      const audioPlayer = document.getElementById("audioPlayer");
      if (audioPlayer && event.start) {
        audioPlayer.currentTime = event.start;
        audioPlayer.play();
      }
    }
	},
};
</script>
<style>
  .timelineCard {
    width: 300px;
  }
	.content {
    font-size: 12px;
    color: #999;
  }
  
  .bottom {
    margin-top: 13px;
    line-height: 12px;
  }

  .image {
    width: 100%;
    height: 250px;
  }

  .clearfix:before,
  .clearfix:after {
      display: table;
      content: "";
  }
  
  .clearfix:after {
      clear: both
  }
</style>
