import { render, staticRenderFns } from "./DataCenter.vue?vue&type=template&id=5d68b0dd&scoped=true&"
import script from "./DataCenter.vue?vue&type=script&lang=js&"
export * from "./DataCenter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d68b0dd",
  null
  
)

export default component.exports