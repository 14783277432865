<template>
  <div>
    <div v-if="url" style="display: flex; align-items: center; height: 100%">
      <audio
        id="audioPlayer"
        :src="url"
        preload="metadata"
        @durationchange="durationChanged"
      ></audio>
      <div style="width: 60px">
        <el-button type="text" @click="playPause">
          <i
            :class="playing ? 'el-icon-video-pause' : 'el-icon-video-play'"
            style="font-size: 30px; color: white"
          ></i>
        </el-button>
      </div>
      <vue-slider
        ref="slider"
        v-model="progress"
        @change="sliderChanged"
        @drag-start="sliderStartDrag"
        :lazy="true"
        tooltip="active"
        style="flex-grow: 4"
        :interval="0.01"
        :tooltipFormatter="percent2time"
      ></vue-slider>
      <div style="width: 100px; text-align: center; color: white">
        <span>{{ timeDisplay }}</span>
      </div>
    </div>
    <div v-else style="color: gray; line-height: 50px">还没有媒体文件</div>
  </div>
</template>
<script>
import { Duration } from "luxon";
import { mapFields } from "vuex-map-fields";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default {
  props: {
    url: String,
  },
  computed: {
    ...mapFields(["playerCurrentTime"]),
  },
  data() {
    return {
      playing: false,
      dragging: false,
      progress: 0,
      duration: 1,
      timeDisplay: "时间",
    };
  },
  components: {
    VueSlider,
  },
  created() {
    window.addEventListener('keypress', this.processKeyPress);
  },
  destroyed() {
    window.removeEventListener('keypress', this.processKeyPress);
  },
  methods: {
    processKeyPress(event) {
      if (event.keyCode === 32) {
        // space bar
        const tagName = event.target.tagName.toUpperCase();
        const isTipTap = event.target.classList.contains("ProseMirror");
        console.log(isTipTap);
        if (tagName != 'INPUT' && tagName != "TEXTAREA" && !isTipTap) {
          this.playPause();
          event.preventDefault();
        }
      }
    },
    playPause() {
      const audioPlayer = document.getElementById("audioPlayer");
      if (audioPlayer) {
        if (audioPlayer.paused) {
          audioPlayer.play();
        } else {
          audioPlayer.pause();
        }
      }
    },
    durationChanged() {
      const audioPlayer = document.getElementById("audioPlayer");
      if (audioPlayer) {
        this.duration = audioPlayer.duration;
        const durationString = Duration.fromMillis(
          this.duration * 1000
        ).toFormat("mm:ss");
        this.timeDisplay = durationString;
        this.$emit("durationChanged", this.duration);
        audioPlayer.onplaying = () => {
          this.playing = true;
        };
        audioPlayer.onpause = () => {
          this.playing = false;
        };
        audioPlayer.ontimeupdate = () => {
          const currentTime = audioPlayer.currentTime;
          this.playerCurrentTime = currentTime;
          if (!this.dragging) {
            this.progress = (currentTime / this.duration) * 100;
          }
          this.timeDisplay =
            Duration.fromMillis(currentTime * 1000).toFormat("mm:ss") +
            "/" +
            durationString;
        };
      }
    },
    sliderStartDrag() {
      //console.log("drag");
      this.dragging = true;
    },
    sliderChanged(value) {
      const audioPlayer = document.getElementById("audioPlayer");
      //console.log(value);
      this.progress = value;
      audioPlayer.currentTime = (value / 100) * this.duration;
      this.dragging = false;
    },
    percent2time(value) {
      return Duration.fromMillis(value * this.duration * 10).toFormat("mm:ss");
    }
  },
};
</script>
